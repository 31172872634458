export const initialFilterState = {
    maxResult: 7,
    startIndex: 0,
    isReqCount: false,
    storeName: '',
    planogramName: '',
    departmentName: '',

    regionIds: [],
    storeIds: [],
    productOrBarcode: '',
    isFilleterByDate: false,
    isFilterBySentDate: false,
    planogramFromDate: '',
    planogramToDate: '',
    sentDateFromDate: '',
    sentDateToDate: '',
    departmentIds: [],
    planogramVersion: [],
    planogramStatus: [],
    isFilleterByConnectedStores: false,
    connectedStoresFrom: 0,
    connectedStoresTo: 0,
    isFilleterByFieldCount: false,
    fieldCountFrom: 0,
    fieldCountTo: 0,
    isFilleterByFieldWidth: false,
    fieldsWidthFrom: 0,
    fieldsWidthTo: 0,
    isReqPagination: true,
    sortBy: '',
    sortOrder: '',
    productOrBarcode: '',
    isContainsArchivedProducts: false,
    isContainsBlockedSalesProducts: false,
    isContainsBlockedOrderProducts: false,
    pageType: 'default', // 1. default 2. byPlanogram, 3. byStore
    isSentDateFilter: false,
    sentDate: ''
}

export const initialSimulationFilterState = {
    maxResult: 7,
    startIndex: 0,
    isReqCount: true,
    planogramName: '',
    departmentName: '',

    productOrBarcode: '',
    isFilleterByLastModifiedDate: false,
    planogramLastModifiedFromDate: '',
    planogramLastModifiedToDate: '',
    departmentIds: [],
    planogramVersion: [],
    isFilleterByFieldCount: false,
    fieldCountFrom: 0,
    fieldCountTo: 0,
    isFilleterByFieldWidth: false,
    fieldsWidthFrom: '',
    fieldsWidthTo: '',
    isReqPagination: true,
    sortBy: '',
    sortOrder: '',
    isContainsArchivedProducts: false,
    isContainsBlockedSalesProducts: false,
    isContainsBlockedOrderProducts: false,
}

export const status = [
    { value: 'ACTIVE', label: 'Active', t: 'Active' },
    { value: 'INACTIVE', label: 'Inactive', t: 'Inactive' },
    // { value: 'DRAFT', label: 'DRAFT' },
    { value: 'CONFIRMED', label: 'Pending', t: 'Pending' },
    {
        value: 'ACTIVE_CONFIRMED',
        label: 'Active & Pending',
        t: 'Active_Pending',
    },
]

export const initialFormState = {
    region: [],
    branch: [],
    productOrBarcode: '',
    archivedProduct: 'archivedProduct',
    blockedProduct: 'blockedProduct',
    blockedOrderProduct: 'blockedOrderProduct',
    radioStatus: '',
    actvationDate: {
        from: '',
        to: '',
    },
    sentDate: {
        from: '',
        to: '',
    },
    department: [],
    version: [],
    status: ['ACTIVE','CONFIRMED'],
    connectedStores: {
        to: null,
        from: null,
    },
    fieldsNumber: {
        to: null,
        from: null,
    },
    fieldsWidth: {
        from: null,
        to: null,
    },
}
